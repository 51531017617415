<template>
  <v-card height="100%" style="border-radius: 0 !important">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline">{{ selectedAgency.name }} - {{ screenName }}</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col v-if="userDoc.role != 'de'" cols="12" sm="4" md="4">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="image-container">
                  <img
                    id="image"
                    :style="
                      !fileSrc
                        ? 'border:1px solid #52525242; min-width: 100%; min-height: 235px; max-height: 235px; object-fit:cover;filter: grayscale(100%);'
                        : ''
                    "
                    class="preview"
                    :src="fileSrc || defaultImage"
                    alt=""
                  />

                  <div style="width: 100%">
                    <i @click="filePicker" class="fas fa-plus add"></i>
                  </div>
                </div>

                <v-progress-linear
                  :value="progress"
                  color="progress-bar"
                  style="border-radius: 5px"
                  class="mt-3"
                  height="30"
                >
                  <strong>{{ Math.ceil(progress) }}%</strong>
                </v-progress-linear>
              </v-col>
              <v-col class="mt-3" cols="4" sm="4" md="4">
                <input v-model="screen.color" type="color" />
              </v-col>
              <v-col class="mt-5" cols="8" sm="8" md="8">
                <p>{{ $t("agencies.directories.primary_color") }}</p>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-select
                  :items="playlists"
                  item-text="name"
                  v-model="screen.defaultPlaylist"
                  rounded
                  filled
                  clearable
                  item-value="id"
                  :loading="loading"
                  :label="$t('agencies.player.default_playlist')"
                  persistent-hint
                  :hint="$t('agencies.player.default_playlist_hint')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.number="screen.inactivityTime"
                  :label="$t('agencies.directories.inactivity_time')"
                  :loading="loading"
                  type="number"
                  clearable
                  :hint="$t('agencies.directories.seconds')"
                  rounded
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="screen.playlist">
                <p class="current-playlist">
                  {{ $t("agencies.player.actually_playlist") }}
                  <span
                    @click="showCurrentPlaylist"
                    style="cursor: pointer"
                    class="font-weight-bold"
                    >{{ currentPlaylistName }}</span
                  >
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <playlists-scheduler
                  v-if="!loading"
                  :schedule="screen.schedule ? screen.schedule : []"
                  @updated="scheduleUpdated"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="mt-3"
            cols="12"
            :sm="userDoc.role == 'de' ? '12' : '8'"
            :md="userDoc.role == 'de' ? '12' : '8'"
          >
            <v-row>
              <p class="ml-5">
                {{ $t("agencies.directories.add_products_message") }}
              </p>
              <v-col cols="12" sm="6" md="6">
                <h3 class="ml-5 mt-4">
                  {{ $t("agencies.directories.what_products") }}
                </h3>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <search />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div
                  style="width: 100%; display: flex; justify-content: flex-end"
                >
                  <v-checkbox
                    @change="selectAll"
                    :label="$tc('agencies.directories.show_all')"
                    v-model="all"
                  ></v-checkbox>
                  <div v-if="!loading" class="counter">
                    <small class="font-weight-bold">{{ counter }}</small>
                  </div>
                </div>

                <files-table
                  :items="products"
                  @menuClick="handleMenuActions($event)"
                  :loading="loading"
                  :headers="headers"
                  @changedVisibility="changedVisibility"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="12" md="12">
            <v-row justify="end">
              <v-btn :loading="loading" @click="save" color="buttons">{{
                $t("button.save")
              }}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-file-input
      accept="image/png, image/jpeg"
      show-size
      style="display: none"
      id="file"
      counter
      :disabled="loading"
      @change="selectedFile"
      label="Seleccionar imagen"
      rounded
      filled
    ></v-file-input>

    <v-dialog
      hide-overlay
      fullscreen
      max-width="60%"
      v-if="viewImageDialog"
      v-model="viewImageDialog"
    >
      <view-file
        :url="selectedItem.images[0].url"
        type="image"
        @cancel="viewImageDialog = false"
      />
    </v-dialog>

    <v-dialog
      fullscreen
      hide-overlay
      v-if="viewPlaylistDialog"
      v-model="viewPlaylistDialog"
    >
      <view-playlist
        :name="selectedPlaylist.name"
        :files="selectedPlaylist.files"
        @cancel="viewPlaylistDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import filesTable from "@/components/files-table";
import { db, fb } from "@/firebase";
import viewPlaylist from "@/views/Player/Playlists/view-playlist";
import remove from "@/components/delete";
import playlistsScheduler from "@/components/playlists-scheduler";
import { mapActions, mapState } from "vuex";
import viewFile from "@/components/view-file";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "directories-configuration",
  props: [
    "selectedAgency",
    "selectedScreen",
    "screenName",
    "selectedCountry",
    "selectedCity",
    "screenToEdit",
  ],
  components: {
    viewPlaylist,
    remove,
    filesTable,
    playlistsScheduler,
    viewFile,
  },
  data() {
    return {
      defaultImage: require("@/assets/wink-placeholder.png"),
      fileSrc: "",
      progress: 0,
      products: [],
      loading: true,
      viewPlaylistDialog: false,
      selectedPlaylist: null,
      selectStatus: false,
      snackbarText: "",
      snackbar: false,
      playlists: [],
      // all: true,
      viewImageDialog: false,
      selectedItem: null,
      file: "",
      scheduler: [],
      screen: {
        color: "#008BBF",
      },
      headers: [
        {
          value: "productSmallImage",
        },
        {
          text: this.$t("headers.name"),
          value: "name",
        },
        {
          text: this.$t("headers.description"),
          value: "description",
          width: "150px",
        },
        {
          text: this.$t("headers.price"),
          value: "price",
        },
        {
          text: this.$t("headers.price_at_this_agency"),
          value: "newPrice",
        },
        {
          text: this.$t("headers.show_the_product"),
          value: "show",
          align: "center",
          width: "70px",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "groupId", "userDoc"]),
    currentPlaylistName() {
      let playlistResult = this.playlists.find(
        (item) => item.id == this.screen.playlist
      );
      return playlistResult ? playlistResult.name : "";
    },
    all() {
      let result = this.products.find(
        (item) => item.show == false || !item.show
      );
      return result ? false : true;
    },

    counter() {
      let marked = this.products.filter((item) => item.show == true);
      return `(${marked.length || 0}/ ${this.products.length})`;
    },
  },
  methods: {
    filePicker() {
      document.getElementById("file").click();
    },

    changedVisibility(e) {
      this.products = this.products.map((item) => {
        let product = item;
        if (product[".key"] == e[".key"]) {
          product.show = e.show;
        }
        return product;
      });
    },

    selectAll(e) {
      this.products = this.products.map((item) => {
        item.show = e;
        return item;
      });
    },
    showCurrentPlaylist() {
      let playlistResult = this.playlists.find(
        (item) => item.id == this.screen.playlist
      );
      this.selectedPlaylist = playlistResult;
      this.viewPlaylistDialog = true;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "show_image":
          if (payload.item.images[0]) {
            this.selectedItem = payload.item;
            this.viewImageDialog = true;
          }
          break;

        default:
          break;
      }
    },
    scheduleUpdated(data) {
      this.scheduler = data;
    },
    selectedFile(e) {
      this.file = e;

      var reader = new FileReader();
      const image = this.file;
      reader.onload = (e) => {
        this.fileSrc = e.target.result;
      };
      reader.readAsDataURL(image);
    },
    selectedProducts() {
      return new Promise((resolve) => {
        let selectedProducts = this.products.filter((item) => item.show);

        selectedProducts = selectedProducts.map((item) => {
          let product = {
            productId: item[".key"],
          };

          if (item.newPrice) {
            product.newPrice = item.newPrice;
          }
          return product;
        });

        resolve(selectedProducts);
      });
    },
    uploadImage() {
      return new Promise((resolve) => {
        if (!this.file) {
          return resolve("success");
        }

        let extension = this.file.type.split("/").pop();
        let filename = `logo_${Math.floor(
          Math.random() * (10000000000 - 10000000) + 10000000
        )}`;
        let dataName = `${filename}.${extension}`;

        var uploadTask = fb
          .app()
          .storage("gs://beanage-images")
          .ref()
          .child(`screens/${this.groupId}/${this.selectedScreen}/${dataName}`)
          .put(this.file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {},
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve({
                downloadURL,
                filename,
              });
            });
          }
        );
      });
    },
    async save() {
      let products = await this.selectedProducts();

      if (!products.length) {
        this.snackbarText = this.$t("agencies.directories.min_products");
        this.snackbar = true;
        return;
      }

      // if (!this.screen.defaultPlaylist) {
      //   this.snackbarText = this.$t("agencies.player.empty_fields");
      //   this.snackbar = true;
      //   return;
      // }

      this.loading = true;

      let imageData = await this.uploadImage();
      let playlist = await this.actualPlaylist();

      let data = {
        color: this.screen.color,
        products,
        schedule: this.scheduler.length ? this.scheduler : [],
        playlist: playlist ? playlist : "",
        modifiedBy: this.user.uid,
        modifiedAt: new Date(),
        isScheduled: this.scheduler.length ? true : false,
        defaultPlaylist: this.screen.defaultPlaylist
          ? this.screen.defaultPlaylist
          : "",
        selectedApp: "directories",
        agency: this.selectedAgency[".key"],
        inactivityTime: this.screen.inactivityTime
          ? this.screen.inactivityTime
          : 30,
      };

      if (imageData.downloadURL) {
        data.image = {
          original: imageData.downloadURL,
          filename: imageData.filename,
        };
      }

      db.collection("screens")
        .doc(this.selectedScreen)
        .update(data)
        .then((response) => {
          this.loading = false;
          this.$emit("success");
        })
        .catch((error) => {
          this.loading = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
        });
    },
    actualPlaylist() {
      return new Promise((resolve, reject) => {
        let today = parseInt(
          moment()
            .tz("America/tegucigalpa")
            .format()
            .substr(0, 10)
            .replace(/-/g, "")
        );

        for (let i = 0; i < this.scheduler.length; i++) {
          const currentSchedule = this.scheduler[i];
          let startDate = parseInt(
            moment(new Date(currentSchedule.start.seconds * 1000))
              .format("YYYY-MM-DD")
              .replaceAll("-", "")
          );

          let endDate = parseInt(
            moment(new Date(currentSchedule.end.seconds * 1000))
              .format("YYYY-MM-DD")
              .replaceAll("-", "")
          );

          if (
            startDate <= today &&
            endDate >= today &&
            currentSchedule.playlist
          ) {
            return resolve(currentSchedule.playlist);
          }
        }

        return resolve(this.screen.defaultPlaylist);
      });
    },
  },
  async mounted() {
    // if (!this.screenToEdit.products || !this.screenToEdit.products.length) {
    //   this.all = false;
    // }

    db.collection("products")
      .where("groupId", "==", this.groupId)
      .onSnapshot((response) => {
        this.products = [];
        response.forEach((item) => {
          let product = item.data();
          product[".key"] = item.id;
          this.products.push(product);
        });
      });

    await db
      .collection("playlists")
      .where("groupId", "==", this.groupId)
      .orderBy("name", "asc")
      .get()
      .then((response) => {
        response.forEach((item) => {
          let playlist = item.data();
          playlist.id = item.id;
          this.playlists.push(playlist);
        });
        this.playlists = this.playlists.filter((item) => item.files.length > 0);
      });
    if (this.screenToEdit && this.screenToEdit.selectedApp == "directories") {
      this.screen = JSON.parse(JSON.stringify(this.screenToEdit));
      this.fileSrc = this.screen.image ? this.screen.image.original : "";

      let productsAddedId = this.screen.products.map((item) => {
        return item.productId;
      });
      this.products.forEach((item) => {
        let searchProduct = this.screen.products.find(
          (productAdded) => productAdded.productId == item[".key"]
        );
        item.show = searchProduct ? true : false;

        // if (!item.show) {
        //   this.all = false;
        // }

        item.newPrice =
          searchProduct && searchProduct.newPrice ? searchProduct.newPrice : "";
      });
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.preview {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  object-fit: contain;
}

.image-container {
  width: 100%;
  margin-top: 5px;
  max-height: 235px;
  position: relative;
}

.add {
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}

#video {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  object-fit: contain;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}

.v-input__slot {
  background-color: transparent !important;
  background: transparent !important;
}

.current-playlist {
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #eeeeee;
  padding: 10px;
  color: black;
  text-align: center;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
</style>