<template>
  <v-row>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-col
      style="justify-content: flex-end; display: flex"
      cols="12"
      sm="12"
      md="12"
    >
      <v-btn rounded filled color="buttons" @click="newScheduler">
        <v-icon left> fas fa-plus </v-icon>
        {{$t('scheduler.add')}}
      </v-btn>
    </v-col>
    <v-col cols="12" sm="12" md="12">
      <v-data-table
        v-if="source.length"
        :headers="headers"
        :items="source"
        item-key="name"
        calculate-widths
        :items-per-page="5"
        sort-by="start"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          <p class="playlist-name">{{ item.name }}</p>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                x-small
                @click="showPlaylist(item)"
                :elevation="0"
                color="rgba(0, 0, 0, 0.6)"
              >
                <v-icon dark>fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{$t('table.view_file')}}</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-1"
                fab
                v-on="on"
                dark
                x-small
                @click="showDeleteDialog(item, index)"
                :elevation="0"
                color="rgba(0, 0, 0, 0.6)"
              >
                <v-icon dark>fa-trash</v-icon>
              </v-btn>
            </template>
            <span>{{$t('table.delete')}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-if="addSchedulerDialog"
      transition="dialog-bottom-transition"
      persistent
      v-model="addSchedulerDialog"
      max-width="600px"
    >
      <add-scheduler
        :playlists="playlists"
        @success="verifyScheduler"
        :selectedPlaylist="selectedPlaylist"
        @cancel="addSchedulerDialog = false"
      />
    </v-dialog>

    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
      v-if="viewPlaylistDialog"
      v-model="viewPlaylistDialog"
    >
      <view-playlist
        :name="selectedPlaylist.name"
        :files="selectedPlaylist.files"
        @cancel="viewPlaylistDialog = false"
      />
    </v-dialog>

    <v-dialog max-width="500px" v-if="deleteDialog" v-model="deleteDialog">
      <remove
        ref="deleteComponent"
        transition="dialog-bottom-transition"
        :name="selectedPlaylist.name"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import addScheduler from "@/components/add-scheduler";
import { db, fb } from "@/firebase";
import viewPlaylist from "../views/Player/Playlists/view-playlist";
import remove from "@/components/delete";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";

export default {
  name: "playlists-scheduler",
  props: ["schedule"],
  components: {
    addScheduler,
    viewPlaylist,
    remove,
  },
  data() {
    return {
      addSchedulerDialog: false,
      selectedPlaylist: null,
      source: [],
      playlists: [],
      snackbarText: "",
      snackbar: false,
      viewPlaylistDialog: false,
      deleteDialog: false,
      index: null,
      headers: [
        {
          text: "Playlist",
          value: "name",
        },
        {
          text: this.$t('headers.start_date'),
          value: "start",
          width: "120px",
        },
        {
          text: this.$t('headers.end_date'),
          value: "end",
          width: "120px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "120px",
        },
      ],
    };
  },
  computed : {
    ...mapState(['groupId'])
  },
  methods: {
    showPlaylist(playlist) {
      this.selectedPlaylist = playlist;
      this.viewPlaylistDialog = true;
    },
    confirmDelete() {
      this.deleteDialog = false;
      this.source.splice(this.index, 1);
      this.sendData();
    },
    showDeleteDialog(playlist, index) {
      this.index = index;
      this.selectedPlaylist = playlist;
      this.deleteDialog = true;
    },
    newScheduler() {
      this.selectedPlaylist = null;
      this.addSchedulerDialog = true;
    },
    verifyScheduler(payload) {
      let eventStart = Number.parseInt(
        payload.start.toString().replaceAll("-", "")
      );

      let eventEnd = Number.parseInt(
        payload.end.toString().replaceAll("-", "")
      );

      let exist = false;

      for (let i = 0; i < this.source.length; i++) {
        const event = this.source[i];

        const start = Number.parseInt(
          event.start.toString().replaceAll("-", "")
        );
        const end = Number.parseInt(event.end.toString().replaceAll("-", ""));

        if (
          (eventStart <= start && eventEnd >= start) ||
          (eventStart >= start && eventEnd <= end) ||
          (eventStart <= end && eventEnd >= end)
        ) {
          this.snackbarText =
            "Solo es permitido asignar una lista de reproducción por dia.";
          this.snackbar = true;
          exist = true;
        }
      }

      if (!exist) {
        this.source.push(payload);
        this.addSchedulerDialog = false;
        this.sendData();
      }
    },
    sendData() {
      let data = this.source.map((item) => {
        let dateStart = item.start.split("-");
        let dateEnd = item.end.split("-");
        return {
          playlist: item.playlist,
          start: fb.firestore.Timestamp.fromDate(
            new Date(
              parseInt(dateStart[0]),
              parseInt(dateStart[1]) - 1,
              parseInt(dateStart[2]),
              parseInt("00"),
              parseInt("00"),
              parseInt("01")
            )
          ),
          end: fb.firestore.Timestamp.fromDate(
            new Date(
              parseInt(dateEnd[0]),
              parseInt(dateEnd[1]) - 1,
              parseInt(dateEnd[2]),
              parseInt("23"),
              parseInt("59"),
              parseInt("59")
            )
          ),
          active: true,
        };
      });

      this.$emit("updated", data);
    },
  },
  async mounted() {

    this.source = JSON.parse(JSON.stringify(this.schedule));

    await db
      .collection("playlists")
      .where("groupId", "==", this.groupId)
      .orderBy("name", "asc")
      .get()
      .then((response) => {
        response.forEach((item) => {
          let playlist = item.data();
          playlist.id = item.id;
          this.playlists.push(playlist);
        });
        this.playlists = this.playlists.filter((item) => item.files.length > 0);
      });

    this.source = this.source && this.source.length ? this.source : [];

    this.source.forEach((item) => {
      let result = this.playlists.find(
        (playlist) => playlist.id == item.playlist
      );
      item.name = result.name;
      item.files = result.files;
      item.filesCount = result.files.length;
      item.start = moment(new Date(item.start.seconds * 1000)).format(
        "YYYY-MM-DD"
      );
      item.end = moment(new Date(item.end.seconds * 1000)).format("YYYY-MM-DD");
    });
  },
};
</script>

<style scoped>
.playlist-name {
  max-width: 130px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 10px;
}
</style>