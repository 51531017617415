<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedAgency.name }} - {{ screenName }}</span
        >
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select
                prepend-icon="fa-th-large"
                class="mt-5"
                :label="$t('agencies.survey.select_survey' )"
                v-model="selectedSurvey"
                item-text="name"
                :items="surveys"
                item-value="id"
                :loading="loading"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                filled
                rounded
                :allow-overflow="false"
                clearable
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 mt-3">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn :loading="loading" @click="save" class="mt-5" color="buttons"
                  >{{$t('dialog.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "zones-configuration",
  props: [
    "selectedAgency",
    "selectedScreen",
    "screenName",
    "selectedCountry",
    "selectedCity",
    "screenToEdit"
  ],
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      zones: [],
      selectedSurvey: "",
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    save() {
      if (this.selectedSurvey) {
        this.loading = true;
        db.collection("screens")
          .doc(this.selectedScreen)
          .update({
            modifiedAt: new Date(),
            modifiedBy: this.user.uid,
            country: this.selectedCountry,
            city: this.selectedCity,
            agency: this.selectedAgency[".key"],
            selectedApp: "survey",
            survey: this.selectedSurvey,
          })
          .then(async (response) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText =
              this.$tc('messages.unknown');
            this.snackbar = true;
          });
      }
    },
    sortedArray: function (data) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return data.sort(compare);
    },
  },
  mounted() {

    if (this.screenToEdit && this.screenToEdit.selectedApp == "survey") { 
      this.selectedSurvey = this.screenToEdit.survey
    }

    db.collection("forms")
      .where("groupId", "==", this.groupId)
      .onSnapshot((response) => {
        this.surveys = [];
        response.forEach((item) => {
          let survey = item.data();
          survey.id = item.id;
          this.surveys.push(survey);
        });
        this.surveys = this.sortedArray(this.surveys);
        this.loading = false;
      });
  },
};
</script>