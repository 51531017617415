<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid class="fill-height">
      <v-row class="header-title-actions">
        <v-col cols="12" sm="4" md="4">
          <h1 class="page-title">{{ $tc("agencies.name", 2) }}</h1>
        </v-col>
        <!-- <v-col cols="12" sm="2" md="2">
          
        </v-col>
        <v-col cols="12" sm="2" md="2">

          
        </v-col> -->
        <v-col class="align-end" cols="12" sm="8" md="8">
          <v-select
            v-model="selectedCountry"
            :items="countries"
            prepend-icon="fa-city"
            :label="$t('agencies.select_country')"
            item-text="name"
            item-value=".key"
            aria-autocomplete="false"
            autocomplete="off"
            @change="getCities"
            hide-details
            filled
            rounded
            :allow-overflow="false"
            clearable
          />
          <v-select
            v-model="selectedCity"
            :items="cities"
            prepend-icon="fa-city"
            :label="$t('agencies.select_city')"
            item-text="name"
            :disabled="!selectedCountry"
            item-value=".key"
            aria-autocomplete="false"
            @change="getAgencies"
            autocomplete="off"
            hide-details
            class="ml-3"
            filled
            rounded
            :allow-overflow="false"
            clearable
          />
          <v-btn
            color="buttons"
            class="ml-3"
            @click="showAddAgencyDialog"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $t("agencies.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="agencies"
            :loading="loading"
            :showView="false"
            :showAddScreens="true"
            :showEdit="true"
            :showDelete="userDoc.role == 'de' ? false : true"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="addAgencyDialog"
      persistent
      v-model="addAgencyDialog"
      max-width="600px"
    >
      <add-agency
        :selectedCountry="selectedCountry"
        :selectedCity="selectedCity"
        :key="randomKey"
        :countries="countries"
        :originalAgency="selectedAgency"
        @success="agencyAdded"
        @cancel="addAgencyDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="screensDialog"
      persistent
      transition="dialog-bottom-transition"
      v-model="screensDialog"
      max-width="1200px"
    >
      <agency-screens
        :key="randomKey"
        :selectedItem="selectedAgency"
        :selectedCountry="selectedCountry"
        :selectedCity="selectedCity"
        @success="handleAddScreen"
        @cancel="screensDialog = false"
      />
    </v-dialog>

    <v-dialog
      max-width="500px"
      v-if="deleteAgencyDialog"
      v-model="deleteAgencyDialog"
    >
      <delete-agency
        ref="deleteComponent"
        :name="selectedAgency.name"
        @success="confirmDelete"
        @cancel="deleteAgencyDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import addAgency from "./add-agency";
import deleteAgency from "@/components/delete";
import filesTable from "@/components/files-table";
import agencyScreens from "./agency-screens.vue";

export default {
  name: "Agencies",
  components: {
    addAgency,
    deleteAgency,
    filesTable,
    agencyScreens,
  },
  data() {
    return {
      agencies: [],
      randomKey: 0,
      snackbarText: "",
      snackbar: false,
      loading: true,
      addAgencyDialog: false,
      screensDialog: false,
      selectedAgency: null,
      deleteAgencyDialog: false,
      countries: [],
      headers: [
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.description"),
          value: "description",
          width: "150px",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "180px",
        },
      ],
      cities: [],
      selectedCountry: "",
      selectedCity: "",
    };
  },

  computed: {
    ...mapState(["user", "groupId", "userDoc"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    showAddAgencyDialog() {
      if (this.selectedCountry && this.selectedCountry) {
        this.randomKey = Math.random();
        this.selectedAgency = null;
        this.addAgencyDialog = true;
      } else {
        this.snackbarText = this.$t("agencies.select_country_and_city");
        this.snackbar = true;
      }
    },

    showscreensDialog(item) {
      this.selectedAgency = item;
      this.screensDialog = true;
    },
    handleAddScreen() {
      this.screensDialog = false;
      this.snackbarText = this.$t("screens.configured");
      this.snackbar = true;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "edit":
          this.editAgency(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
        case "add_screens":
          this.showscreensDialog(payload.item);
          break;
      }
    },

    getAgencies(cityId) {
      if (cityId) {
        this.loading = true;
        this.$binding(
          "agencies",
          db
            .collection("agencies")
            .where("groupId", "==", this.groupId)
            .where("city", "==", this.selectedCity)
        ).then((res) => {
          this.loading = false;
        });
      } else this.agencies = [];
    },
    editAgency(item) {
      this.randomKey = Math.random();
      this.selectedAgency = item;
      this.addAgencyDialog = true;
    },

    showDeleteDialog(item) {
      this.selectedAgency = item;
      this.deleteAgencyDialog = true;
    },

    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("agencies")
        .doc(this.selectedAgency[".key"])
        .delete()
        .then((response) => {
          this.snackbarText = this.$t("agencies.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteAgencyDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },

    agencyAdded() {
      this.addAgencyDialog = false;
      this.snackbarText = this.selectedAgency
        ? this.$t("agencies.edited")
        : this.$t("agencies.added");
      this.snackbar = true;
    },

    getCities(countryId) {
      if (countryId) {
        this.$binding(
          "cities",
          db
            .collection("cities")
            .where("country", "==", this.selectedCountry)
            .orderBy("name", "asc")
        );
      } else {
        this.selectedCity = null;
        this.cities = [];
        this.agencies = [];
      }
    },
  },
  async mounted() {
    this.cleanSearch();

    await this.$binding("countries", db.collection("countries"));
    this.loading = false;
  },
};
</script>