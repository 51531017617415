<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ selectedItem.name }}</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col v-if="!screenToEdit" cols="12" sm="12" md="12">
              <v-autocomplete
                v-model="selectedScreen"
                :items="screens"
                prepend-icon="fa-desktop"
                class="mt-5"
                :label="$t('agencies.screens.select_screen')"
                item-text="name"
                :loading="loading"
                :no-data-text="$t('table.no_data_text')"
                @change="screenChanged"
                item-value="id"
                hide-details
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                v-model="selectedApp"
                :items="apps"
                prepend-icon="fa-shapes"
                class="mt-5"
                :disabled="userDoc.role == 'de'"
                :label="$t('agencies.screens.select_content')"
                item-text="name"
                item-value="url"
                hide-details
                :loading="loading"
                :no-data-text="$t('table.no_data_text')"
                filled
                rounded
                :allow-overflow="false"
                clearable
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ data.item.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 mt-3">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn @click="next" :loading="loading" color="buttons">{{
                  $t("button.next")
                }}</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="configurationDialog"
      persistent
      v-model="configurationDialog"
      :max-width="selectedApp == 'directories' ? '1500px' : '600px'"
    >
      <zones-configuration
        v-if="selectedApp == 'zones'"
        :screenName="screenName"
        :selectedScreen="selectedScreen"
        :selectedAgency="selectedItem"
        :selectedCity="selectedCity"
        :screenToEdit="screenToEdit"
        @success="handleSuccess"
        :selectedCountry="selectedCountry"
        @cancel="configurationDialog = false"
      />

      <player-configuration
        v-if="selectedApp == 'player'"
        :screenName="screenName"
        :selectedScreen="selectedScreen"
        :screenToEdit="screenToEdit"
        :selectedAgency="selectedItem"
        :selectedCity="selectedCity"
        @success="handleSuccess"
        :selectedCountry="selectedCountry"
        @cancel="configurationDialog = false"
      />

      <trivia
        v-if="selectedApp == 'trivia'"
        :screenName="screenName"
        :selectedScreen="selectedScreen"
        :screenToEdit="screenToEdit"
        :selectedAgency="selectedItem"
        :selectedGame="screenToEdit ? screenToEdit.gameId : ''"
        :selectedCity="selectedCity"
        @success="handleSuccess"
        :selectedCountry="selectedCountry"
        @cancel="configurationDialog = false"
      />

      <directory-configuration
        v-if="selectedApp == 'directories'"
        :screenName="screenName"
        :selectedScreen="selectedScreen"
        :screenToEdit="screenToEdit"
        :selectedAgency="selectedItem"
        :selectedCity="selectedCity"
        @success="handleSuccess"
        :selectedCountry="selectedCountry"
        @cancel="configurationDialog = false"
      />

      <survey-configuration
        v-if="selectedApp == 'survey'"
        :screenName="screenName"
        :selectedScreen="selectedScreen"
        :selectedAgency="selectedItem"
        :selectedCity="selectedCity"
        :screenToEdit="screenToEdit"
        @success="handleSuccess"
        :selectedCountry="selectedCountry"
        @cancel="configurationDialog = false"
      />

      <roulette
        v-if="selectedApp == 'roulette'"
        :screenName="screenName"
        :selectedScreen="selectedScreen"
        :selectedAgency="selectedItem"
        :selectedCity="selectedCity"
        :screenToEdit="screenToEdit"
        @success="handleSuccess"
        :selectedCountry="selectedCountry"
        @cancel="configurationDialog = false"
        :selectedGame="screenToEdit ? screenToEdit.rouletteId : ''"

      />
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";
import zonesConfiguration from "./apps-configuration/zones";
import playerConfiguration from "./apps-configuration/player";
import directoryConfiguration from "./apps-configuration/directories";
import surveyConfiguration from "./apps-configuration/survey";
import trivia from "./apps-configuration/trivia";
import roulette from "./apps-configuration/roulette.vue";


export default {
  name: "add-screen",
  props: ["selectedCity", "selectedCountry", "selectedItem", "screenToEdit"],
  components: {
    zonesConfiguration,
    playerConfiguration,
    directoryConfiguration,
    surveyConfiguration,
    trivia,
    roulette
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      screens: [],
      selectedApp: "",
      selectedScreen: "",
      apps: [
        {
          name: this.$t("apps.player.name"),
          description: this.$tc("apps.player.description"),
          url: "player",
        },
        {
          name: this.$t("apps.multizone.name"),
          description: this.$tc("apps.multizone.description"),
          url: "zones",
        },
        {
          name: this.$t("apps.directories.name"),
          description: this.$tc("apps.directories.description"),
          url: "directories",
        },
        {
          name: this.$t("apps.survey.name"),
          description: this.$tc("apps.survey.description"),
          url: "survey",
        },

        {
          name: this.$t("apps.trivia.name"),
          description: this.$tc("apps.trivia.description"),
          url: "trivia",
        },

        {
          name: this.$t("apps.roulette.name"),
          description: this.$tc("apps.roulette.description"),
          url: "roulette",
        },
      ],
      configurationDialog: false,
      screenName: "",
    };
  },
  // filters: {
  //   filterApp(item) {
  //     const apps = {
  //       player: "Contenido Multimedia",
  //       zones: "Multizonas",
  //       directory: "Directorios Táctiles",
  //     };
  //     return item ? apps[item] : "Sin configuración";
  //   },
  // },
  computed: {
    ...mapState(["user", "groupId", "userDoc"]),
  },

  methods: {
    sortedArray: function (data) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return data.sort(compare);
    },
    handleSuccess() {
      this.configurationDialog = false;
      this.$emit("success");
    },
    screenChanged(screenId) {
      this.screenName =
        this.screens.find((item) => item.id == screenId).name || "";
    },
    next() {
      if (this.selectedApp && this.selectedScreen) {
        this.configurationDialog = true;
      }
    },
  },
  mounted() {
    // console.log(this.screenToEdit);

    if (this.screenToEdit) {
      this.selectedApp = this.screenToEdit.selectedApp;
      this.selectedScreen = this.screenToEdit.id;
      this.screenName = this.screenToEdit.name;
    }

    db.collection("screens")
      .where("groupId", "==", this.groupId)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let screen = item.data();
          screen.id = item.id;
          this.screens.push(screen);
        });
        this.screens = this.sortedArray(this.screens);
        this.screens = this.screens.filter((item) => !item.selectedApp);
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.status {
  position: relative;
  right: 0;
  float: right;
}
</style>