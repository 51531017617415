<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">
          {{
            $tc("agencies.screens.name", selectedItem.name, {
              agency: selectedItem.name,
            })
          }}</span
        >
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <search :loading="loading" />
            </v-col>
            <v-col class="align-end" cols="12" sm="12" md="12">
              <v-btn
                color="buttons"
                @click="showAddScreenDialog"
                :loading="loading"
              >
                <v-icon left> fas fa-plus </v-icon>
                {{ $tc("agencies.screens.configure_screen") }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <files-table
                @menuClick="handleMenuActions($event)"
                :items="screens"
                :loading="loading"
                :showEdit="true"
                :showResetScreen="userDoc.role == 'de' ? false : true"
                :headers="headers"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="addScreenDialog"
      persistent
      v-model="addScreenDialog"
      max-width="600px"
    >
      <add-screen
        :key="randomKey"
        :screenToEdit="screenToEdit"
        :selectedItem="selectedItem"
        :selectedCity="selectedCity"
        :selectedCountry="selectedCountry"
        @success="handleAddScreen"
        @cancel="addScreenDialog = false"
      />
    </v-dialog>

    <v-dialog max-width="500px" v-if="deleteDialog" v-model="deleteDialog">
      <confirm
        ref="confirmComponent"
        :name="
          $tc('agencies.screens.clear_configuration_name', screenToEdit.name, {
            screenName: screenToEdit.name,
          })
        "
        @success="confirmReset"
        :message="$t('agencies.screens.clear_configuration_message')"
        @cancel="deleteDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import filesTable from "@/components/files-table";
import addScreen from "./add-agency-screen";
import confirm from "@/components/confirm";

export default {
  name: "agency-screens",
  props: ["selectedItem", "selectedCountry", "selectedCity"],
  components: {
    filesTable,
    addScreen,
    confirm,
  },

  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      screens: [],
      addScreenDialog: false,
      screenToEdit: null,
      deleteDialog: false,
      headers: [
        {
          text: this.$t("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$t("headers.description"),
          value: "description",
          width: "150px",
        },

        {
          text: this.$t("headers.configured_content"),
          value: "selectedApp",
          width: "250px",
        },
        {
          text: this.$t("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  computed: {
    ...mapState(["user", "groupId", "userDoc"]),
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    confirmReset() {
      this.$refs.confirmComponent.loading = true;
      db.collection("screens")
        .doc(this.screenToEdit.id)
        .update({
          selectedApp: fb.firestore.FieldValue.delete(),
          isScheduled: fb.firestore.FieldValue.delete(),
          schedule: fb.firestore.FieldValue.delete(),
          agency: fb.firestore.FieldValue.delete(),
          country: fb.firestore.FieldValue.delete(),
          city: fb.firestore.FieldValue.delete(),
          zone: fb.firestore.FieldValue.delete(),
          products: fb.firestore.FieldValue.delete(),
          defaultPlaylist: fb.firestore.FieldValue.delete(),
          playlist: fb.firestore.FieldValue.delete(),
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
        })
        .then((res) => {
          this.snackbarText =
            "La configuración de esta pantalla se ha eliminado.";
          this.snackbar = true;
          this.$refs.confirmComponent.loading = false;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.confirmComponent.loading = false;
        });
    },
    showAddScreenDialog() {
      this.screenToEdit = null;
      this.addScreenDialog = true;
    },
    handleAddScreen() {
      this.addScreenDialog = false;
      this.snackbarText = this.$t("agencies.screens.configured");
      this.snackbar = true;
    },
    editScreen(item) {
      this.screenToEdit = item;
      this.addScreenDialog = true;
    },
    showDeleteDialog(item) {
      this.screenToEdit = item;
      this.deleteDialog = true;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "edit":
          this.editScreen(payload.item);
          break;
        case "reset_screen":
          this.showDeleteDialog(payload.item);
          break;
      }
    },
  },
  mounted() {
    this.cleanSearch();
    db.collection("screens")
      .where("groupId", "==", this.groupId)
      .where("agency", "==", this.selectedItem[".key"])
      .onSnapshot((response) => {
        this.screens = [];
        response.forEach((item) => {
          let screen = item.data();
          screen.id = item.id;
          this.screens.push(screen);
        });
        this.loading = false;
      });
  },
};
</script>