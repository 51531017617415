<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedAgency.name }} - {{ screenName }}</span
        >
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select
                :items="playlists"
                item-text="name"
                rounded
                filled
                item-value="id"
                :loading="loading"
                v-model="screen.defaultPlaylist"
                :label="$t('agencies.player.default_playlist')"
                persistent-hint
                :hint="$t('agencies.player.default_playlist_hint')"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="screen.playlist">
              <p class="current-playlist">
                {{$t('agencies.player.actually_playlist' )}}
                <span
                  style="cursor: pointer"
                  @click="showCurrentPlaylist"
                  class="font-weight-bold"
                  >{{ currentPlaylistName }}</span
                >
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <playlists-scheduler
                v-if="!loading"
                :schedule="screen.schedule ? screen.schedule : []"
                @updated="scheduleUpdated"
              />
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  @click="save"
                  style="z-index: 999"
                  class="save-btn"
                  color="buttons"
                  >{{$t('button.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog
      fullscreen
      hide-overlay
      v-if="viewPlaylistDialog"
      v-model="viewPlaylistDialog"
    >
      <view-playlist
        :name="selectedPlaylist.name"
        :files="selectedPlaylist.files"
        @cancel="viewPlaylistDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import viewPlaylist from "@/views/Player/Playlists/view-playlist";
import playlistsScheduler from "@/components/playlists-scheduler";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "player-configuration",
  props: [
    "selectedAgency",
    "selectedScreen",
    "screenName",
    "selectedCountry",
    "selectedCity",
    "screenToEdit",
  ],
  components: {
    viewPlaylist,
    playlistsScheduler,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      playlists: [],
      screen: {
        name: "",
        description: "",
      },
      scheduler: [],
      viewPlaylistDialog: false,
      selectedPlaylist: null,
      deleteDialog: false,
      headers: [
        {
          text: "Playlist",
          value: "name",
          width: "100px",
        },
        {
          text: "Fecha inicial",
          value: "start",
          width: "120px",
        },
        {
          text: "Fecha final",
          value: "end",
          width: "120px",
        },
        {
          text: "Archivos",
          value: "filesCount",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "120px",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
    currentPlaylistName() {
      let playlistResult = this.playlists.find(
        (item) => item.id == this.screen.playlist
      );
      return playlistResult ? playlistResult.name : "";
    },
  },

  methods: {
    showPlaylist(playlist) {
      this.selectedPlaylist = playlist;
      this.viewPlaylistDialog = true;
    },
    scheduleUpdated(data) {
      this.scheduler = data;
    },
    showCurrentPlaylist() {
      let playlistResult = this.playlists.find(
        (item) => item.id == this.screen.playlist
      );
      this.selectedPlaylist = playlistResult;
      this.viewPlaylistDialog = true;
    },
    actualPlaylist() {
      return new Promise((resolve, reject) => {
        let today = parseInt(
          moment()
            .tz("America/tegucigalpa")
            .format()
            .substr(0, 10)
            .replace(/-/g, "")
        );

        for (let i = 0; i < this.scheduler.length; i++) {
          const currentSchedule = this.scheduler[i];

          let startDate = parseInt(
            moment(new Date(currentSchedule.start.seconds * 1000))
              .format("YYYY-MM-DD")
              .replaceAll("-", "")
          );

          let endDate = parseInt(
            moment(new Date(currentSchedule.end.seconds * 1000))
              .format("YYYY-MM-DD")
              .replaceAll("-", "")
          );

          if (
            startDate <= today &&
            endDate >= today &&
            currentSchedule.playlist
          ) {
            return resolve(currentSchedule.playlist);
          }
        }

        return resolve(this.screen.defaultPlaylist);
      });
    },
    showDeleteDialog(playlist, index) {
      this.index = index;
      this.selectedPlaylist = playlist;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.deleteDialog = false;
      this.scheduler.splice(this.index, 1);
    },
    async save() {
      let playlist = await this.actualPlaylist();

      if (this.screen.defaultPlaylist) {
        this.loading = true;
        db.collection("screens")
          .doc(this.selectedScreen)
          .update({
            playlist: playlist,
            modifiedAt: new Date(),
            modifiedBy: this.user.uid,
            schedule: this.scheduler.length ? this.scheduler : [],
            defaultPlaylist: this.screen.defaultPlaylist,
            isScheduled: this.scheduler.length ? true : false,
            selectedApp: "player",
            agency: this.selectedAgency[".key"]
          })
          .then((response) => {
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText =
              this.$tc('messages.unknown');
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$t('agencies.player.empty_fields');
        this.snackbar = true;
      }
    }
  },

  async mounted() {
    this.loading = true;
    await db
      .collection("playlists")
      .where("groupId", "==", this.groupId)
      .orderBy("name", "asc")
      .get()
      .then((response) => {
        response.forEach((item) => {
          let playlist = item.data();
          playlist.id = item.id;
          this.playlists.push(playlist);
        });
        this.playlists = this.playlists.filter((item) => item.files.length > 0);
      });

    if (this.screenToEdit && this.screenToEdit.selectedApp == "player") {
      this.screen = JSON.parse(JSON.stringify(this.screenToEdit));
      this.screen.defaultPlaylist = this.screen.defaultPlaylist
        ? this.screen.defaultPlaylist
        : this.screen.playlist;
    }

    this.loading = false;

  },
};
</script>

<style scoped>
.list-group {
  display: grid !important;
  grid-template-columns: 33% 33% 33%;
  min-width: 400px;
}
.cards-container {
  background-color: #e1dbf07d;
  border-radius: 20px;
  padding: 20px;
  min-height: 400px;
  overflow-y: auto;
}

.v-card > *:first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.card {
  background-color: rgb(83, 81, 81);
  padding: 20px;
  border-radius: 10px;
  color: white;
}
.files-count {
  position: absolute;
  right: 20px;
  color: gray;
}

.card-icon {
  font-size: 20px;
}
.playlist-name {
  max-width: 200px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.current-playlist {
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #eeeeee;
  padding: 10px;
  color: black;
  text-align: center;
}
</style>