<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ originalAgency ? $t("dialog.edit") : $t("dialog.add") }}
          {{ $tc("agencies.name", 1) }}</span
        >
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="agency.name"
                :label="$t('dialog.name')"
                :loading="loading"
                clearable
                rounded
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-textarea
                v-model="agency.description"
                :label="$t('dialog.description')"
                :loading="loading"
                rounded
                rows="5"
                filled
                clearable
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  class="save-btn"
                  @click="originalAgency ? updateAgency() : addAgency()"
                  color="buttons"
                  >{{ $t("button.save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-agency",
  props: ["originalAgency", "countries", "selectedCity", "selectedCountry"],
  data() {
    return {
      agency: {
        name: "",
        description: "",
        country: "",
        city: "",
      },
      snackbarText: "",
      cities: [],
      snackbar: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  watch: {
    "agency.country"(countryId) {
      this.$binding(
        "cities",
        db.collection("cities").where("country", "==", countryId)
      );
    },
  },
  methods: {
    updateAgency() {
      if (this.agency.name && this.agency.city && this.agency.country) {
        this.loading = true;

        db.collection("agencies")
          .doc(this.originalAgency[".key"])
          .update({
            name: this.agency.name,
            description: this.agency.description,
            city: this.agency.city,
            country: this.agency.country,
            modifiedAt: new Date(),
            modifiedBy: this.user.uid,
          })
          .then(async (response) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = this.$t("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$t("agencies.empty_fields");
        this.snackbar = true;
      }
    },
    addAgency() {
      if (this.agency.name) {
        this.loading = true;
        db.collection("agencies")
          .add({
            name: this.agency.name,
            description: this.agency.description,
            city: this.selectedCity,
            country: this.selectedCountry,
            createdAt: new Date(),
            createdBy: this.user.uid,
            groupId: this.groupId,
          })
          .then(async (response) => {
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = this.$t("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$t("agencies.empty_fields");
        this.snackbar = true;
      }
    },
  },
  async mounted() {
    this.loading = false;

    if (this.originalAgency) {
      this.agency = Object.assign({}, this.originalAgency);
    }
  },
};
</script>