<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{$t('scheduler.add')}}</span
        >
        <v-btn
          style="right: 10px; top: 10px; border-radius : 30px!important"
          icon
          color="buttons"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-select
                :items="playlists"
                item-text="name"
                rounded
                filled
                item-value="id"
                :loading="loading"
                v-model="event.playlist"
                :label="$t('scheduler.playlist')"
                persistent-hint
                :hint="$t('scheduler.playlist_hint')"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date[0]"
                    :label="$t('headers.start_date')"
                    prepend-icon="fas fa-calendar"
                    readonly
                    rounded
                    filled
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date[0]"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date[1]"
                    :label="$t('headers.end_date')"
                    prepend-icon="fas fa-calendar"
                    readonly
                    rounded
                    filled
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date[1]"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                :loading="loading"
                class="flex-grow-1 flex-shrink-0 white--text"
                large
                @click="add"
                rounded
                :elevation="0"
                :minHeight="56"
                block
                color="buttons"
              >
                {{$t('button.save')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";

export default {
  name: "add-schedule",
  props: ["playlists", "selectedPlaylist"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      startDateMenu: false,
      date: ["", ""],
      endDateMenu: false,
      event: {
        playlist: "",
      },
    };
  },
  methods: {
    async add() {
      if (this.event.playlist && this.date[0] && this.date[1]) {
        let selectedPlaylist = this.playlists.find(
          (item) => item.id == this.event.playlist
        );
        this.event.name = selectedPlaylist.name;
        this.event.filesCount = selectedPlaylist.files ? selectedPlaylist.files.length : 0
        this.event.start = this.date[0]
        this.event.end = this.date[1]
        this.event.files = selectedPlaylist.files
        this.$emit("success", this.event);
      } else {
        this.snackbarText = this.$t('messages.insert_all_fields');
        this.snackbar = true;
      }
    },
    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.date[0].split("-");
      
        this.event.start = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("00"),
            parseInt("00"),
            parseInt("01")
          )
        );
        date = this.date[1].split("-");
        this.event.end = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("23"),
            parseInt("59"),
            parseInt("59")
          )
        );
        resolve(true);
      });
    },
  },
  mounted() {
    this.event = Object.assign({}, this.selectedPlaylist);
    this.date[0] = this.event.start
    this.date[1] = this.event.end
  },
};
</script>

<style scoped>
</style>